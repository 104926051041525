import Link from 'next/link';
import React from 'react';

import { LogoIcon } from '@/components/_icons/logo-icon';

import { StyledLogoWrapper } from './styled';

type Props = {
    href?: string;
    color?: string;
};

export const Logo: React.FC<Props> = ({ href = '/', color }) => {
    return (
        <StyledLogoWrapper>
            <Link href={href}>
                <LogoIcon color={color} />
            </Link>
        </StyledLogoWrapper>
    );
};
