import React from 'react';

import { SvgIcon } from '@/types/types';

export const ExitIcon: SvgIcon = ({ color = '#A9ADBC', width = 16 }) => {
    return (
        <svg width={width} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M6.14 10.86C5.88 11.12 5.46 11.12 5.2 10.86L2.80667 8.46667C2.54667 8.20667 2.54667 7.78667 2.80667 7.52667L5.2 5.13333C5.46 4.87333 5.88 4.87333 6.14 5.13333C6.4 5.39333 6.4 5.81333 6.14 6.07333L4.88667 7.33333H10.6667C11.0333 7.33333 11.3333 7.63333 11.3333 8C11.3333 8.36667 11.0333 8.66667 10.6667 8.66667H4.88667L6.14 9.92C6.4 10.18 6.39333 10.6067 6.14 10.86ZM12.6667 2H3.33333C2.59333 2 2 2.6 2 3.33333V4C2 4.36667 2.3 4.66667 2.66667 4.66667C3.03333 4.66667 3.33333 4.36667 3.33333 4C3.33333 3.63333 3.63333 3.33333 4 3.33333H12C12.3667 3.33333 12.6667 3.63333 12.6667 4V12C12.6667 12.3667 12.3667 12.6667 12 12.6667H4C3.63333 12.6667 3.33333 12.3667 3.33333 12C3.33333 11.6333 3.03333 11.3333 2.66667 11.3333C2.3 11.3333 2 11.6333 2 12V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2Z" />
        </svg>
    );
};
