import { styled } from 'styled-components';

import { mq } from '../_shared/media.styled';

export const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const StyledMain = styled.main<{ $noMarginDesc?: boolean; $noMarginMob: boolean; $noMargin?: boolean }>`
    flex-grow: 1;
    margin-top: ${({ $noMarginDesc }) => ($noMarginDesc ? '0px' : ' 32px')};
    margin-top: ${({ $noMargin }) => ($noMargin ? '0px' : ' 32px')};
    margin-bottom: 32px;

    ${mq.lt.md} {
        margin-top: ${({ $noMarginDesc }) => ($noMarginDesc ? '110px' : ' 134px')};
        margin-top: ${({ $noMargin }) => ($noMargin ? '110px' : ' 134px')};
        ${({ $noMarginMob }) => $noMarginMob && 'margin-top: 0px'};
    }
`;
