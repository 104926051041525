export const BASE_URL = '/';

export const OFFICE_URL = '/offices';

export const FAVORITE_URL = '/favorite';

export const LOGIN_URL = '/sign-in';

export const BASKET_URL = '/basket';

export const DOCUMENT_URL = '/documents';

export const SEARCH_URL = '/search';

export const HOW_IT_WORKS_URL = '/how-it-works';

export const PROFILE_URL = '/account/profile';

export const BANK_CARDS_URL = '/account/bank-cards';

export const PAYMENT_RENT_URL = '/account/rent-payment';

export const ORDERS_URL = '/account/orders';

export const REGISTRATION_URL = '/registration';

export const ARTICLES_URL = '/articles';

export const QUESTIONS_URL = '/questions';

export const NOTIFICATIONS_CARDS_URL = '/notifications';

export const PRODUCT_URL = '/product';

export const BASKET_SUBMIT_URL = '/basket/submit';
