import { styled } from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 16px;

    h5 {
        color: ${({ theme }) => theme.color.grey};
        font-size: ${({ theme }) => theme.fontSize.sm};
        margin-bottom: 22px;
    }

    ul {
        padding-left: 8px;
    }
`;
