import React, { ComponentProps } from 'react';

import { StyledCheckboxContainer, StyledCustomCheckbox, StyledInputCheckbox } from './styled';

type TProps = Omit<ComponentProps<'input'>, 'size'> & {
    name: string;
    containerClass?: string;
};

export const Checkbox: React.FC<TProps> = ({ children, onChange, name, checked, containerClass }) => {
    return (
        <StyledCheckboxContainer className={containerClass}>
            <StyledCustomCheckbox>
                <StyledInputCheckbox id={name} name={name} type="checkbox" checked={checked} onChange={onChange} />
                <div> {children} </div>
            </StyledCustomCheckbox>
        </StyledCheckboxContainer>
    );
};
