import { styled } from 'styled-components';

export const StyledCheckboxContainer = styled.div`
    background-color: transparent;
`;

export const StyledCustomCheckbox = styled.label`
    div {
        display: inline-flex;
        align-items: center;
        user-select: none;
        color: ${({ theme }) => theme.color.dark};
        opacity: 0.7;

        &::before {
            content: '';
            display: inline-block;
            width: 1em;
            height: 1em;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid #adb5bd;
            border-radius: 0.25em;
            margin-right: 0.5em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
        }
    }

    & > input:not(:disabled, :checked) + div:hover::before {
        border-color: #b3d7ff;
    }

    & > input:not(:disabled):active + div::before {
        background-color: #b3d7ff;
        border-color: #b3d7ff;
    }

    & > input:checked + div::before {
        border-color: #00d9de;
        background-color: #00d9de;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
`;

export const StyledInputCheckbox = styled.input`
    position: absolute;
    z-index: -1;
    opacity: 0;
`;
