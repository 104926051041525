import { TokenService } from '@/api/baseServices/tokenService/token-service';
import { useAppDispatch } from '@/store/hooks';
import { restoreInformationSignIn } from '@/store/reducers/sign-in';
import { resetUserData } from '@/store/reducers/user/slice';

type TReturnedUseLogout = {
    logout: () => void;
};

export const useLogout = (): TReturnedUseLogout => {
    const dispatch = useAppDispatch();

    const logout = (): void => {
        dispatch(resetUserData());

        dispatch(restoreInformationSignIn());

        TokenService.removeTokens();
    };

    return { logout };
};
