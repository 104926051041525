import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { ArrowIcon } from '@/components/_icons/arrow-icon';
import { BankCardIcon } from '@/components/_icons/bank-card-icon';
import { BlogIcon } from '@/components/_icons/blog-icon';
import { EnterIcon } from '@/components/_icons/enter-icon';
import { ExitIcon } from '@/components/_icons/exit-icon';
import { HowWorkIcon } from '@/components/_icons/how-work-icon';
import { LocationIcon } from '@/components/_icons/location-icon';
import { Location2Icon } from '@/components/_icons/location2-icon';
import { NotificationIcon } from '@/components/_icons/notification-icon';
import { PopularQuestionsIcon } from '@/components/_icons/popular-questions-icon';
import { UserDataIcon } from '@/components/_icons/user-data-icon';
import { LinkBlock } from '@/components/elements/link-block';
import { Logo } from '@/components/elements/logo';
import { Button } from '@/components/elements/UI/button';
import { MenuList } from '@/components/layout/header/menu-list/ui/menu-list';
import { NavButton } from '@/components/layout/header/nav-area/styled';
import { BANK_CARDS_URL, LOGIN_URL, PROFILE_URL, REGISTRATION_URL } from '@/constants/paths';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { showModal } from '@/store/reducers/modal/slice';

import { useLogout } from '@/shared/hooks/use-logout';
import { useTranslate } from '@/shared/hooks/use-translate';

const ChooseCity = dynamic(() => import('../../modules/choose-city'), { ssr: false });
import {
    StyledBurgerOverlay,
    StyledMenuInBurger,
    StyledMenuInBurgerAuthAction,
    StyledMenuInBurgerBody,
    StyledMenuInBurgerCity,
    StyledMenuInBurgerLinksList,
    StyledMenuInBurgerLinksListItem,
} from './styled';

type Props = {
    open: boolean;
    setOpen: (val: boolean) => void;
};

type TIconByUrl = '/offices' | '/questions' | '/how-it-works' | '/articles';

export const MenuInBurger: React.FC<Props> = ({ open, setOpen }) => {
    const theme = useTheme();
    const router = useRouter();
    const dispatch = useAppDispatch();

    const { t } = useTranslate();

    const mobileMenu = useAppSelector((state) => state.menus.menu.data?.mobileMenu);

    const { auth, cityUser } = useAppSelector((state) => state.user);

    const { logout } = useLogout();

    const { authenticated: isAuth, isUserLead } = auth;

    const getIconByUrl = {
        '/offices': <Location2Icon />,
        '/questions': <PopularQuestionsIcon />,
        '/how-it-works': <HowWorkIcon />,
        '/articles': <BlogIcon />,
    };

    const handleOpenModal = () => {
        setOpen(false);

        dispatch(showModal({ size: 'Large', content: <ChooseCity />, title: '' }));
    };

    const handleCloseBurger = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [open]);

    return (
        <>
            {open && <StyledBurgerOverlay onClick={handleCloseBurger} />}
            <StyledMenuInBurger open={open}>
                <StyledMenuInBurgerBody className={isAuth ? 'auth' : ''}>
                    <StyledMenuInBurgerCity>
                        <Logo />
                        <NavButton onClick={handleOpenModal}>
                            <LocationIcon width={12} /> {cityUser?.name}
                        </NavButton>
                    </StyledMenuInBurgerCity>
                    <MenuList onItemClick={handleCloseBurger} />
                    <hr />
                    <StyledMenuInBurgerLinksList>
                        {isAuth && (
                            <>
                                <StyledMenuInBurgerLinksListItem onClick={handleCloseBurger}>
                                    <LinkBlock href={isUserLead ? REGISTRATION_URL : PROFILE_URL}>
                                        <span>
                                            <UserDataIcon /> {t('Мои личные данные')}
                                        </span>
                                        <span>
                                            <ArrowIcon color="#A9ADBC" />
                                        </span>
                                    </LinkBlock>
                                </StyledMenuInBurgerLinksListItem>

                                <StyledMenuInBurgerLinksListItem onClick={handleCloseBurger}>
                                    <LinkBlock href={isUserLead ? REGISTRATION_URL : PROFILE_URL}>
                                        <span>
                                            <NotificationIcon /> {t('Уведомления')}
                                        </span>
                                        <span>
                                            <ArrowIcon color="#A9ADBC" />
                                        </span>
                                    </LinkBlock>
                                </StyledMenuInBurgerLinksListItem>
                                <StyledMenuInBurgerLinksListItem onClick={handleCloseBurger}>
                                    <LinkBlock href={isUserLead ? REGISTRATION_URL : BANK_CARDS_URL}>
                                        <span>
                                            <BankCardIcon /> {t('Банковские карты')}
                                        </span>
                                        <span>
                                            <ArrowIcon color="#A9ADBC" />
                                        </span>
                                    </LinkBlock>
                                </StyledMenuInBurgerLinksListItem>
                            </>
                        )}
                        {mobileMenu?.items.map((el) => (
                            <StyledMenuInBurgerLinksListItem key={el.name} onClick={handleCloseBurger}>
                                <LinkBlock href={el.url}>
                                    <span>
                                        {getIconByUrl[el.url as TIconByUrl]} {el.name}
                                    </span>
                                    <span>
                                        <ArrowIcon color="#A9ADBC" />
                                    </span>
                                </LinkBlock>
                            </StyledMenuInBurgerLinksListItem>
                        ))}
                    </StyledMenuInBurgerLinksList>
                    <StyledMenuInBurgerAuthAction>
                        {isAuth && (
                            <Button
                                type="button"
                                variant="primary"
                                onClick={() => {
                                    handleCloseBurger();
                                    logout();
                                    router.push(LOGIN_URL);
                                }}
                                icon={<ExitIcon width={16} color={theme.color.secondary} />}
                            >
                                <span>{t('Выйти')}</span>
                            </Button>
                        )}
                        {!isAuth && (
                            <>
                                <Button
                                    type="button"
                                    variant="primary"
                                    onClick={() => {
                                        handleCloseBurger();
                                        router.push(LOGIN_URL);
                                    }}
                                    icon={<EnterIcon width={15} color={theme.color.secondary} />}
                                >
                                    <span>{t('Войти')}</span>
                                </Button>
                                <p>
                                    {t('Чтобы брать товары в аренду')}, <br /> {t('войдите или создайте аккаунт')} 😇
                                </p>
                            </>
                        )}
                    </StyledMenuInBurgerAuthAction>
                </StyledMenuInBurgerBody>
            </StyledMenuInBurger>
        </>
    );
};
