import { styled } from 'styled-components';

import { Button } from '../button';

export const StyledDropdownModalContainer = styled.div<{ withBg: boolean }>`
    display: flex;
    flex-direction: column;

    ${({ withBg, theme }) =>
        withBg
            ? `
            background: ${theme.color.secondary};
            padding: 10px;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
            border-radius: 12px;        
        `
            : ''}
    .ant-dropdown-menu {
        box-shadow: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`;

export const StyledDropdownModalTitle = styled.h3`
    color: ${({ theme }) => theme.color.dark};
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-weight: 500;
    text-align: left;
    margin: 12px;
`;

export const StyledDropdownModalButton = styled(Button).attrs({
    variant: 'primary',
    fontSize: 'xs',
})`
    border-radius: 4px;
    font-weight: 700;
    margin: 12px;
`;
