import { styled } from 'styled-components';

import { mq } from '@/components/_shared/media.styled';
import { Button } from '@/components/elements/UI/button';

export const NavContainer = styled.div`
    display: flex;

    & > * {
        margin-right: 24px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const NavButton = styled(Button)`
    color: ${({ theme }) => theme.color.dark};

    & {
        &.hide-by-sm {
            display: none;
        }

        ${mq.gt.sm} {
            &.hide-by-sm {
                display: flex;
            }
        }
    }

    & > svg {
        margin-right: 12px;
    }
`;

export const NavList = styled.nav`
    display: none;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    ${mq.gt.sm} {
        display: flex;
    }
`;
