import { styled } from 'styled-components';

export const Link = styled.span`
    color: ${({ theme }) => theme.color.neutral500};
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color.primary};
    }
`;
