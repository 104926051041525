import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import { APP_BASE_IMAGE_PATH, IMAGE_ALT_CUT_LENGTH } from '@/constants/settings';
import { useAppSelector } from '@/store/hooks';
import { cutString } from '@/utils/string';

import * as Styled from './styled';

export const SocialLinks: FC = () => {
    const links = useAppSelector((state) => state.socialLinks.data);

    if (links.length === 0) {
        return <></>;
    }

    return (
        <Styled.Wrapper>
            {links.map((item, index) => {
                return (
                    <Link href={item.link} key={`soc-link-${index}`} target="_blank">
                        <div>
                            <Image
                                fill
                                src={`${APP_BASE_IMAGE_PATH}/${item.iconUrl}`}
                                alt={cutString(item?.name, IMAGE_ALT_CUT_LENGTH)}
                            />
                        </div>
                    </Link>
                );
            })}
        </Styled.Wrapper>
    );
};
