import { styled } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    a {
        display: flex;
        width: 44px;
        height: 44px;
        align-items: center;
        background-color: rgb(255 255 255 / 10%);
        justify-content: center;
        border-radius: 8px;

        > div {
            position: relative;
            display: flex;
            align-items: center;
            width: 27px;
            height: 27px;
        }
    }
`;
