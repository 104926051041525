import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { StyledLinkBlock } from './styled';

type TProps = {
    href: string;
    children: React.ReactNode;
    hoverColor?: 'primary' | 'default';
    onClick?: () => void;
};

export const LinkBlock: React.FC<TProps> = ({ href = '/', children, hoverColor = 'primary', onClick }) => {
    const { push } = useRouter();
    if (onClick) {
        return (
            <StyledLinkBlock
                onClick={() => {
                    push(href);
                    onClick();
                }}
                $hoverColor={hoverColor}
            >
                {children}
            </StyledLinkBlock>
        );
    }
    return (
        <Link href={href}>
            <StyledLinkBlock $hoverColor={hoverColor}>{children}</StyledLinkBlock>
        </Link>
    );
};
