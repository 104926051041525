import { styled } from 'styled-components';

import { mq } from '@/components/_shared/media.styled';
import { Button } from '@/components/elements/UI/button';

export const Wrapper = styled(Button).attrs({
    variant: 'primary',
    fontSize: 'md',
})`
    margin-right: 12px;
    padding-left: 11px;
    padding-right: 14px;
    position: relative;
    height: 100%;

    & > svg {
        margin-right: 12px;
    }

    & {
        display: none;

        ${mq.gt.sm} {
            display: flex;
        }
    }
`;
