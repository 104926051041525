import { styled } from 'styled-components';

import { Link } from '@/components/_shared/link';
import { mq } from '@/components/_shared/media.styled';

export const PhoneContainer = styled.div`
    display: none;
    justify-content: end;
    align-items: center;

    ${mq.gt.sm} {
        display: flex;
    }
`;

export const PhoneLink = styled(Link)`
    margin-right: 24px;
    display: none;
    cursor: pointer;

    ${mq.gt.md} {
        display: initial;
    }
`;
