import { styled, css } from 'styled-components';

const burgerOpen = css`
    transform: translateX(3px);

    > * {
        &:nth-child(2) {
            display: none;
        }

        &:nth-child(1) {
            transform: rotate(45deg) translateY(4.5px);
        }

        &:nth-child(3) {
            transform: rotate(-45deg) translateY(-4.5px);
        }
    }
`;

export const StyledBurger = styled.div<{ open?: boolean }>`
    position: relative;
    z-index: 6;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > * {
        width: 18px;
        border: 1px solid ${({ theme }) => theme.color.primary};
        margin: 2px 0;
        transition: all 0.1s;
    }
    ${(p) => p.open && burgerOpen}
`;
