import { styled } from 'styled-components';

import { mq } from '@/components/_shared/media.styled';
import { rowXBetweenYCenter } from '@/components/_shared/mixins.styled';

export const StyledFooter = styled.footer`
    background-color: ${({ theme }) => theme.color.dark};
    color: ${({ theme }) => theme.color.neutral500};
    font-size: 14px;
    padding-top: 34px;
    padding-bottom: 39px;

    ${mq.lt.md} {
        padding-bottom: 16px;
    }
`;

export const StyledFooterLogoSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 42px;
    justify-content: space-between;

    ${mq.lt.sm} {
        flex-direction: column;
    }

    .links {
        display: flex;
        ${mq.lt.sm} {
            margin-top: 32px;
            flex-direction: column-reverse;
            align-items: center;
        }
    }
`;

export const StyledFooterTextButton = styled.div`
    margin-left: 10px;
    ${mq.lt.sm} {
        margin-left: 0;
        margin-bottom: 20px;
    }

    & > div {
        height: 100%;
    }

    a {
        height: 100%;
        font-size: 1rem;
        font-weight: 700;
        padding: 12px 24px;
    }
`;

export const StyledFooterNavsSection = styled.div`
    display: grid;
    align-items: start;

    ${mq.gt.sm} {
        grid-template-columns: 1fr 1fr;
    }
`;

export const StyledFooterNav = styled.nav`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 42px;

    &:last-of-type {
        margin-bottom: 0;
    }
    ${mq.lt.md} {
        margin-bottom: 0;
    }
    ${mq.gt.sm} {
        margin-bottom: 0;
    }
`;

export const StyledFooterNavTitle = styled.h2`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: ${({ theme }) => theme.color.neutral400};
    font-size: 1rem;
    font-weight: 700;
    width: 100%;
    margin: 0 0 20px;
`;

export const StyledFooterNavList = styled.ul`
    width: 100%;
    line-height: 1.3;
    padding-left: 12px;
    margin-bottom: 14px;

    &.mobile {
        display: none;

        &.open {
            display: block;
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    & > li {
        margin-bottom: 18px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    ${mq.lt.md} {
        margin-bottom: 20px !important;
    }

    ${mq.gt.sm} {
        flex: 1;
        padding-left: 0;
        margin-bottom: 0;

        & > li {
            margin-bottom: 14px;
        }
    }
`;

export const StyledFooterInfoSection = styled.div`
    .column {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 42px;
        ${mq.lt.md} {
            flex-direction: column;
            align-items: center;
        }

        .column__item {
            &:first-child {
                max-width: 300px;
                width: 100%;
                ${mq.lt.md} {
                    text-align: center;
                }
            }

            &:last-child {
                max-width: 400px;
                width: 100%;
                text-align: right;
                line-height: 18px;
                ${mq.lt.md} {
                    text-align: center;
                    margin-top: 10px;
                }
            }

            > p {
                margin-bottom: 10px;

                a {
                    color: ${({ theme }) => theme.color.secondary};
                }
            }

            .item__action {
                display: flex;
                justify-content: center;

                button {
                    display: flex;
                    margin-top: 15px;
                    background: #353a59ff;
                    color: ${({ theme }) => theme.color.secondary};
                }
            }
        }
    }

    .copyright {
        margin-top: 20px;
        text-align: center;
        ${mq.lt.md} {
            margin-top: 40px;
        }
    }

    .contentBlocks {
        p {
            line-height: 18px;
        }
    }
`;

export const StyledFooterLk = styled.footer`
    background-color: ${({ theme }) => theme.color.dark};
    padding: 24px;

    & > div {
        ${rowXBetweenYCenter};
    }

    p {
        color: ${({ theme }) => theme.color.grey};
        font-size: ${({ theme }) => theme.fontSize.sm};
    }

    ${mq.lt.md} {
        & div {
            flex-direction: column;
        }

        p {
            margin-top: 24px;
        }
    }
`;
