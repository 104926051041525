import { styled, css } from 'styled-components';

import { rowXBetweenYCenter } from '@/components/_shared/mixins.styled';

const menuOpen = css`
    transform: translateX(0);
    transition: all 0.5s ease;
`;

export const StyledMenuInBurger = styled.div<{ open: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% - 40px);
    z-index: 7;
    transform: translateX(-150%);
    background: #fff;
    transition: all 0.5s ease;
    ${({ open }) => open && menuOpen}
`;

export const StyledMenuInBurgerBody = styled.div`
    padding: 15px 20px 10px;
    max-height: calc(100vh - 100px);

    &.auth {
        max-height: 100vh;
    }

    overflow: auto;

    hr {
        opacity: 0.6;
        margin: 30px 0;
    }
`;

export const StyledMenuInBurgerCity = styled.p`
    ${rowXBetweenYCenter};
    font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const StyledMenuInBurgerLinksList = styled.ul`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`;

export const StyledMenuInBurgerAuthAction = styled.div`
    background: ${({ theme }) => theme.color.secondary};
    box-shadow: 0 0 8px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    align-items: flex-start;

    p {
        color: ${({ theme }) => theme.color.grey};
        font-size: ${({ theme }) => theme.fontSize.xs};
    }
`;

export const StyledMenuInBurgerLinksListItem = styled.li`
    margin-bottom: 22px;

    a {
        color: ${({ theme }) => theme.color.grey};
        font-size: ${({ theme }) => theme.fontSize.sm};
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;

        > div {
            display: flex;
            flex: 1;
            justify-content: space-between;

            > span {
                display: flex;

                &:first-child {
                    gap: 10px;
                }
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const StyledBurgerOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 6;
    background: rgb(0 0 0 / 10%);
`;
