import { styled, css } from 'styled-components';

import { FontSizeLinkButton, VariantLinkButton } from '@/components/elements/UI/link-button/types';

type TButtonProps = {
    $variant: VariantLinkButton;
    $fontSize: FontSizeLinkButton;
    $outlined: boolean;
};

const getFilledButtonCss = (backgroundColor: string, textColor: string) => css`
    border: none;
    background-color: ${backgroundColor};
    color: ${textColor};

    :hover {
        color: ${textColor};
    }
`;

const getOutlinedButtonCss = (borderColor: string, textColor: string) => css`
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: ${borderColor};
    color: ${textColor};

    :hover {
        color: ${textColor};
    }
`;

const neutralFilledCss = css(({ theme }) => getFilledButtonCss(theme.color.neutral200, theme.color.dark));

const primaryFilledCss = css(({ theme }) => getFilledButtonCss(theme.color.primary, theme.color.secondary));

const lightFilledCss = css(({ theme }) => getFilledButtonCss('rgba(255, 255, 255, 0.1)', theme.color.secondary));

const darkFilledCss = css(({ theme }) => getFilledButtonCss(theme.color.dark, theme.color.secondary));

const neutralOutlinedCss = css(({ theme }) => getOutlinedButtonCss(theme.color.neutral500, theme.color.neutral500));

const primaryOutlinedCss = css(({ theme }) => getOutlinedButtonCss(theme.color.primary, theme.color.primary));

const lightOutlinedCss = css(({ theme }) => getOutlinedButtonCss(theme.color.secondary, theme.color.secondary));

const darkOutlinedCss = css(({ theme }) => getOutlinedButtonCss(theme.color.dark, theme.color.secondary));

/*
 * Const disabledCss = css`
 *     pointer-events: none;
 *     cursor: default;
 *
 *     color: ${({ theme }) => theme.color.secondary};
 *     width: 100%;
 *     background: #d3d5db;
 * `;
 */

const filledVariants = {
    primary: primaryFilledCss,
    neutral: neutralFilledCss,
    light: lightFilledCss,
    dark: darkFilledCss,
};

const outlinedVariants = {
    primary: primaryOutlinedCss,
    neutral: neutralOutlinedCss,
    light: lightOutlinedCss,
    dark: darkOutlinedCss,
};

export const StyledLinkButtonWrapper = styled.div<TButtonProps & { $stretch: boolean }>`
    display: inline-block;

    ${(props) => props.$stretch && 'width: 100%;'}
    a {
        display: flex;
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        font-size: ${({ theme, $fontSize }) => theme.fontSize[$fontSize]};
        font-weight: 500;
        line-height: 1.2;
        padding: 9px 16px;
        justify-content: center;
        ${({ $variant, $outlined }) => ($outlined ? outlinedVariants[$variant] : filledVariants[$variant])};
    }
`;
