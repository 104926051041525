import Link from 'next/link';
import React, { FC, HTMLAttributeAnchorTarget } from 'react';

import { StyledLinkButtonWrapper } from '@/components/elements/UI/link-button/styled';
import { FontSizeLinkButton, VariantLinkButton } from '@/components/elements/UI/link-button/types';

type Props = {
    slug: string;
    children: React.ReactNode;
    target?: HTMLAttributeAnchorTarget | undefined;
    variant?: VariantLinkButton;
    fontSize?: FontSizeLinkButton;
    stretch?: boolean;
    outlined?: boolean;
    className?: string;
};

export const LinkButton: FC<Props> = ({
    slug,
    children,
    target,
    stretch = false,
    fontSize = 'sm',
    variant = 'neutral',
    outlined = false,
    className = '',
}) => {
    return (
        <StyledLinkButtonWrapper
            className={className}
            $stretch={stretch}
            $variant={variant}
            $fontSize={fontSize}
            $outlined={outlined}
        >
            <Link href={slug} {...(target && { target: target })}>
                {children}
            </Link>
        </StyledLinkButtonWrapper>
    );
};
