import { styled } from 'styled-components';

export const Wrapper = styled.span`
    a {
        color: ${({ theme }) => theme.color.dark};
        font-size: ${({ theme }) => theme.fontSize.sm};
        display: flex;
        justify-content: space-between;
        margin-bottom: 22px;
        font-weight: 500;
        cursor: pointer;

        > div {
            display: flex;
            flex: 1;
            justify-content: space-between;
            gap: 10px;

            span {
                :first-child {
                    margin-top: -1px;
                }
            }
        }
    }

    &:last-child {
        a {
            margin-bottom: 0;
        }
    }
`;
