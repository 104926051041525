import { styled } from 'styled-components';

import { mq } from './media.styled';

export const StyledContainer = styled.div.attrs({ id: 'container' })`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1280px;
    padding-left: 12px;
    padding-right: 12px;

    ${mq.gt.sm} {
        padding-left: 32px;
        padding-right: 32px;
    }
`;

export const StyledDynamicPageWrapper = styled.div`
    h1 {
        margin-bottom: 40px;
    }

    .content-dynamic-page {
        padding: calc(15px + 15 * (100vw - 320px) / 850);
        background-color: #fff;
        border-radius: 8px;

        * {
            line-height: 2.2rem;
            font-size: 1.4rem;
        }

        h1 {
            font-size: 1.8rem;
        }

        ol,
        ul {
            list-style: decimal;
            margin-left: 25px;
            margin-bottom: 10px;
        }
    }
`;
