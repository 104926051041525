import { message } from 'antd';

export const copyValueToClipboard = async (value: string): Promise<void> => {
    if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(value).then(() => {
            message.success('Телефон был успешно скопирован!');
        });
    } else {
        document.execCommand('copy', true, value);

        message.success('Телефон был успешно скопирован!');
    }
};
