import Link from 'next/link';
import React from 'react';

import { StyledContainer } from '@/components/_shared/container';
import { Link as CustomLink } from '@/components/_shared/link';
import { Logo } from '@/components/elements/logo';
import { ShowContentByBreakPoint } from '@/components/elements/show-by-breakpoint';
import { Button } from '@/components/elements/UI/button';
import { LinkButton } from '@/components/elements/UI/link-button';
import { FooterNavigate } from '@/components/layout/footer/footer-navigate';
import { SocialLinks } from '@/components/modules/social-links';
import { DOCUMENT_URL, OFFICE_URL } from '@/constants/paths';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { showModal } from '@/store/reducers/modal/slice';

import { Callback } from '@/features/callback';

import { FooterContacts } from '@/entities/content-block';

import { useTranslate } from '@/shared/hooks/use-translate';

import {
    StyledFooter,
    StyledFooterLogoSection,
    StyledFooterTextButton,
    StyledFooterNavsSection,
    StyledFooterInfoSection,
    StyledFooterLk,
} from './styled';

export const Footer = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const { t } = useTranslate();

    const menu = useAppSelector((state) => state.menus.menu.data);

    const contentBlocks = useAppSelector((state) => state.contentBlocks.contentBlocks.data);

    const handleOpenModal = () => {
        dispatch(showModal({ size: 'Small', title: t('Закажите обратный звонок'), content: <Callback /> }));
    };

    return (
        <StyledFooter>
            <StyledContainer>
                <StyledFooterLogoSection>
                    <Logo />
                    <div className="links">
                        <SocialLinks />
                        <StyledFooterTextButton>
                            <LinkButton slug={OFFICE_URL} variant="light">
                                {t('Карта отделений')}
                            </LinkButton>
                        </StyledFooterTextButton>
                    </div>
                </StyledFooterLogoSection>
                <StyledFooterNavsSection>
                    <FooterNavigate
                        titleGroup={t('Каталог товаров')}
                        itemsLink={menu?.footerMenuCatalog?.items ? menu?.footerMenuCatalog?.items : []}
                    />
                    <FooterNavigate
                        titleGroup={t('Полезные статьи')}
                        itemsLink={menu?.footerMenuArticles?.items ? menu?.footerMenuArticles?.items : []}
                    />
                </StyledFooterNavsSection>
                <StyledFooterInfoSection>
                    <div className="column">
                        <div className="column__item">
                            <p>
                                <Link href={DOCUMENT_URL}>
                                    <CustomLink>{t('Документы')}</CustomLink>
                                </Link>
                            </p>
                            <div>
                                {contentBlocks?.contacts && <FooterContacts />}
                                <ShowContentByBreakPoint showIf="md">
                                    <div className="item__action">
                                        <Button onClick={handleOpenModal} aria-label={t('Заказать звонок')}>
                                            {t('Заказать звонок')}
                                        </Button>
                                    </div>
                                </ShowContentByBreakPoint>
                            </div>
                        </div>
                        <div className="column__item">
                            <div>
                                {contentBlocks?.legal_address && (
                                    <div
                                        className="contentBlocks"
                                        dangerouslySetInnerHTML={{ __html: contentBlocks?.legal_address.content }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <p>{t(`${new Date().getFullYear()} © Arenta. Все права защищены`)}</p>
                    </div>
                </StyledFooterInfoSection>
            </StyledContainer>
        </StyledFooter>
    );
};

export const FooterLk: React.FC = () => {
    return (
        <StyledFooterLk>
            <StyledContainer>
                <Logo />
                <p>{new Date().getFullYear()} © Arenta. Все права защищены</p>
            </StyledContainer>
        </StyledFooterLk>
    );
};
