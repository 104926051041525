import { FC, ReactNode } from 'react';

import { Footer, FooterLk } from './footer';
import { Header, HeaderLogoOnly } from './header';
import { StyledLayout, StyledMain } from './styled';

type Props = {
    children: ReactNode;
    isFooterLk?: boolean;
    isOnlyHeaderLogo?: boolean;
    noMarginDesc?: boolean;
    noMarginMob?: boolean;
    noMargin?: boolean;
};

export const Layout: FC<Props> = ({
    children,
    isFooterLk = false,
    isOnlyHeaderLogo = false,
    noMarginDesc = false,
    noMarginMob = false,
    noMargin = false,
}) => {
    return (
        <>
            <StyledLayout>
                {isOnlyHeaderLogo ? <HeaderLogoOnly /> : <Header />}
                <StyledMain $noMarginDesc={noMarginDesc} $noMarginMob={noMarginMob} $noMargin={noMargin}>
                    {children}
                </StyledMain>
                {!isFooterLk ? <Footer /> : <FooterLk />}
            </StyledLayout>
        </>
    );
};
