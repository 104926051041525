import { Grid } from 'antd';
import React from 'react';

const { useBreakpoint } = Grid;

type Props = {
    children: React.ReactNode;
    showIf: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};

export const ShowContentByBreakPoint: React.FC<Props> = ({ children, showIf }) => {
    const screen = useBreakpoint();

    return !screen[showIf] ? <>{children}</> : <></>;
};
