import React from 'react';

import { SvgIcon } from '@/types/types';

export const ArrowDown2Icon: SvgIcon = ({ color = '#A9ADBC', width = 17 }): JSX.Element => {
    return (
        <svg width={width} height="16" viewBox="0 0 17 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.74714 6.19334L8.3338 8.78L10.9205 6.19334C11.1805 5.93334 11.6005 5.93334 11.8605 6.19334C12.1205 6.45334 12.1205 6.87334 11.8605 7.13334L8.80047 10.1933C8.54047 10.4533 8.12047 10.4533 7.86047 10.1933L4.80047 7.13334C4.54047 6.87334 4.54047 6.45334 4.80047 6.19334C5.06047 5.94 5.48714 5.93334 5.74714 6.19334Z"
                fill={color}
            />
        </svg>
    );
};
