import React from 'react';

import { SvgIcon } from '@/types/types';

export const BankCardIcon: SvgIcon = ({ width = 16, color = '#A9ADBC' }): JSX.Element => {
    return (
        <svg width={width} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 2.66675H2.66667C1.92667 2.66675 1.34 3.26008 1.34 4.00008L1.33334 12.0001C1.33334 12.7401 1.92667 13.3334 2.66667 13.3334H13.3333C14.0733 13.3334 14.6667 12.7401 14.6667 12.0001V4.00008C14.6667 3.26008 14.0733 2.66675 13.3333 2.66675ZM13.3333 12.0001H2.66667V8.00008H13.3333V12.0001ZM13.3333 5.33341H2.66667V4.00008H13.3333V5.33341Z" />
        </svg>
    );
};
