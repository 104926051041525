import { Grid } from 'antd';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useCallback, useState } from 'react';

import { LocationIcon } from '@/components/_icons/location-icon';
import { Link as CustomLink } from '@/components/_shared/link';
import { Logo } from '@/components/elements/logo';
import { Burger } from '@/components/elements/UI/burger';
import { MenuInBurger } from '@/components/modules/menu-in-burger';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { showModal } from '@/store/reducers/modal/slice';

import { NavContainer, NavButton, NavList } from './styled';

const { useBreakpoint } = Grid;

type Props = {
    className?: string;
};

const ChooseCity = dynamic(() => import('../../../modules/choose-city'), { ssr: false });

const HeaderNavArea: React.FC<Props> = ({ className }) => {
    const [openBurger, setOpenBurger] = useState<boolean>(false);

    const screen = useBreakpoint();

    const dispatch = useAppDispatch();

    const headerMenu = useAppSelector((state) => state.menus.menu.data?.headerMenu);

    const { cityUser } = useAppSelector((state) => state.user);

    const handleChangeIsOpenBurger = useCallback(() => {
        setOpenBurger(true);
    }, []);

    const handleOpenModal = () => {
        dispatch(showModal({ size: 'Large', content: <ChooseCity /> }));
    };

    // TODO: Разрешить скролить при открытом меню-бургере в перевернутом телефоне

    return (
        <NavContainer className={className}>
            {!screen.md && (
                <>
                    <Burger open={openBurger} onClick={handleChangeIsOpenBurger} />
                    <MenuInBurger open={openBurger} setOpen={setOpenBurger} />
                </>
            )}
            <Logo />
            <NavButton className="hide-by-sm" onClick={handleOpenModal}>
                <LocationIcon width={12} /> {cityUser?.name}
            </NavButton>
            <NavList>
                {headerMenu?.items.map((el) => (
                    <Link href={el.url} key={el.position}>
                        <CustomLink>{el.name}</CustomLink>
                    </Link>
                ))}
            </NavList>
        </NavContainer>
    );
};

export default HeaderNavArea;
