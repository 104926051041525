import React from 'react';

import { SvgIcon } from '@/types/types';

export const PopularQuestionsIcon: SvgIcon = ({ width = 16, color = '#A9ADBC' }): JSX.Element => {
    return (
        <svg width={width} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33334 15.7267V13.3267C3.99334 13.1534 1.33334 10.3801 1.33334 7.00008C1.33334 3.50675 4.17334 0.666748 7.66667 0.666748C11.16 0.666748 14 3.50675 14 7.00008C14 10.3001 11.7067 13.6201 8.28667 15.2667L7.33334 15.7267ZM7.66667 2.00008C4.90667 2.00008 2.66667 4.24008 2.66667 7.00008C2.66667 9.76008 4.90667 12.0001 7.66667 12.0001H8.66667V13.5334C11.0933 12.0001 12.6667 9.48008 12.6667 7.00008C12.6667 4.24008 10.4267 2.00008 7.66667 2.00008ZM7 9.66675H8.33334V11.0001H7V9.66675ZM8.33334 8.66675H7C7 6.50008 9 6.66675 9 5.33341C9 4.60008 8.4 4.00008 7.66667 4.00008C6.93334 4.00008 6.33334 4.60008 6.33334 5.33341H5C5 3.86008 6.19334 2.66675 7.66667 2.66675C9.14 2.66675 10.3333 3.86008 10.3333 5.33341C10.3333 7.00008 8.33334 7.16675 8.33334 8.66675Z" />
        </svg>
    );
};
