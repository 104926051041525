import React from 'react';

import { SvgIcon } from '@/types/types';

export const BurgerIcon: SvgIcon = ({ color = '#FFFFFF', width = 18 }): JSX.Element => {
    return (
        <svg width={width} fill={color} viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 12H17C17.55 12 18 11.55 18 11C18 10.45 17.55 10 17 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12ZM1 7H17C17.55 7 18 6.55 18 6C18 5.45 17.55 5 17 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7ZM0 1C0 1.55 0.45 2 1 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H1C0.45 0 0 0.45 0 1Z" />
        </svg>
    );
};
