import { styled } from 'styled-components';

export const StyledLinkBlock = styled.div<{ $hoverColor: 'primary' | 'default' }>`
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease-in;

    svg {
        transition: fill 0.3s ease-in;
    }

    ${({ $hoverColor, theme }) =>
        $hoverColor === 'primary' &&
        `
    &:hover {
        color: ${theme.color.primary};
        transition: color 0.3s ease-out;
        svg {
            fill: ${theme.color.primary};
            transition: fill 0.3s ease-out;
        }
    }
    `}
`;
