import React, { FC, Fragment, ReactElement } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';

import { useCatalogList } from '../../hooks/use-catalog-list';

import * as Styled from './styled';

type TProps = {
    onItemClick?: () => void;
};

export const MenuList: FC<TProps> = ({ onItemClick }) => {
    const { t } = useTranslate();
    const { menuList } = useCatalogList({ onItemClick });

    return (
        <Styled.Wrapper>
            <h5>{t('Каталог')}</h5>
            {menuList.map((value) => {
                const item = value as { label: ReactElement };
                return <Fragment key={value?.key}>{item.label}</Fragment>;
            })}
        </Styled.Wrapper>
    );
};
